import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/images/logo.png";
import KeyboardArrowRightTwoToneIcon from "@mui/icons-material/KeyboardArrowRightTwoTone";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import _fetch from "../config/api";
import { api_url, incomesList, shortTitle, title } from "../config/config";
import toasted from "../config/toast";
import { AddCard, AdminPanelSettings, CurrencyExchange, LogoutRounded, MailLock, Settings, Wallet, Window } from "@mui/icons-material";

const Header = () => {


  const [RightSIdebar, setRightSIdebar] = useState(false);
  const onOpenRightSIdebar = () => setRightSIdebar(true);
  const onCloseRightSIdebar = () => setRightSIdebar(false);

  const navigate = useNavigate();
  let location = useLocation();



  const togglerBarRemove = () => {
    const aRemove: HTMLElement | any = document.body;
    aRemove.classList.remove("show_header__mobail");
  };


  useEffect(() => {

    // if (auth === "true") {
    (async () => {

      let res = await _fetch(`${api_url}home/dashboard`, "GET", {})
      if (res?.status === "success") {
      }
      else {
        localStorage.clear();
        navigate("/login");
      }
    })();
    // }
  }, [location.pathname]);


  const Logout = async () => {

    var data = await _fetch(`${api_url}auth/logout`, 'GET', {});

    if (data?.status === 'success') {

      toasted.success(data?.message);
      localStorage.clear();
      window.location.reload();
      navigate('/login');
    } else {
      toasted.error(data?.message);
    }
  }


  const HeaderData = [
    // Dashboard
    {
      LinkIcon: <Window />,
      LinkName: "Dashboard",
      LinkUrl: '/dashboard',
      dropdown: null
    },
    // Participants
    {
      LinkIcon: <AdminPanelSettings />,
      LinkName: "Participants",
      LinkUrl: '#',

      dropdown: [{
        dropLinkName: "All Participants",
        dropLinkUrl: '/user',
      },
      // {
      //   dropLinkName: "Active Participants",
      //   dropLinkUrl: '/paidUsers',
      // },
      // {
      //   dropLinkName: "Inactive Participants",
      //   dropLinkUrl: '/inactiveUsers',
      // },
      {
        dropLinkName: "Blocked Participants",
        dropLinkUrl: '/blockedUsers',
      }
      ],
    },
    // Settings
    {
      LinkIcon: <Settings />,
      LinkName: "Settings",
      LinkUrl: '#',

      dropdown: [
      //   {
      //   dropLinkName: "News",
      //   dropLinkUrl: '/news',
      // },
      // {
      //   dropLinkName: "User Popup Banner",
      //   dropLinkUrl: '/popup_upload',
      // },

      {
        dropLinkName: "Settings",
        dropLinkUrl: '/withdrawsetting',
      },
      ],
    },

    {
      LinkIcon: <Settings />,
      LinkName: "LDP Program",
      LinkUrl: '#',

      dropdown: [
    
      {
        dropLinkName: "LDP Details",
        dropLinkUrl: '/ldp-details',
      },
      ],
    },
    // Incomes
    {
      LinkIcon: <Wallet />,
      LinkName: "Incomes",
      LinkUrl: '#',


      dropdown: [
        { dropLinkUrl: '/income/referral_airdrop', dropLinkName: 'Referral Airdrop' },
        { dropLinkUrl: '/income/staking_bonus', dropLinkName: 'Staking Bonus' },
        // { dropLinkUrl: '/income/token_income', dropLinkName: 'Token  Income' },
        { dropLinkUrl: '/income/booster_airdrop', dropLinkName: 'Booster Airdrop' },
        { dropLinkUrl: '/income/structure_airdrop', dropLinkName: 'Structure Airdrop' },
        { dropLinkUrl: '/income/airdrop_reward', dropLinkName: 'Airdrop Reward' },

        

        {
          dropLinkName: "All Incomes",
          dropLinkUrl: '/allincomes',
        }
      ],
    },
    {
      LinkIcon: <Wallet />,
      LinkName: "Fund Management",
      LinkUrl: '#',


      dropdown: [
        
        { dropLinkUrl: '/fund/fund_transfer', dropLinkName: 'Fund Transfer' },
        { dropLinkUrl: '/fund/account_upgrade', dropLinkName: 'Account Upgrade' },
        { dropLinkUrl: '/fund/pool_income', dropLinkName: 'Pool Income' },
      ],
    },

    {
      LinkIcon: <Wallet />,
      LinkName: "Royalty",
      LinkUrl: '#',


      dropdown: [
        { dropLinkUrl: '/rank-user/1', dropLinkName: 'Royalty $1.5' },
        { dropLinkUrl: '/rank-user/2', dropLinkName: 'Royalty $5' },
        { dropLinkUrl: '/rank-user/3', dropLinkName: 'Royalty $15' },
        { dropLinkUrl: '/rank-user/4', dropLinkName: 'Royalty $40' },
        { dropLinkUrl: '/rank-user/5', dropLinkName: 'Royalty $70' },
        { dropLinkUrl: '/rank-user/6', dropLinkName: 'Royalty $125' },
        { dropLinkUrl: '/rank-user/7', dropLinkName: 'Royalty $250' },
        { dropLinkUrl: '/rank-user/8', dropLinkName: 'Royalty $500' },
        { dropLinkUrl: '/rank-user/9', dropLinkName: 'Royalty $1000' },
        { dropLinkUrl: '/rank-user/10', dropLinkName: 'Royalty $2000' },
        { dropLinkUrl: '/rank-user/11', dropLinkName: 'Royalty $5000' },

       
      ],
    },
    // Withdraw
    {
      LinkIcon: <CurrencyExchange />,
      LinkName: "Withdraw",
      LinkUrl: '#',

      dropdown: [{
        dropLinkName: "Withdraw Request",
        dropLinkUrl: '/Withdraw/withdraw_request',
      },
      {
        dropLinkName: "Approved Withdraw Request",
        dropLinkUrl: '/Approved/withdraw_request',
      },
      {
        dropLinkName: "Pending Withdraw Request",
        dropLinkUrl: '/Pending/withdraw_request',
      },
      {
        dropLinkName: "Rejected Withdraw Request",
        dropLinkUrl: '/Rejected/withdraw_request',
      },
      {
        dropLinkName: "Non working Withdraw Request",
        dropLinkUrl: '/Withdraw-staking',
      },
      {
        dropLinkName: "Non working Approved Withdraw Request",
        dropLinkUrl: '/Withdraw-Approved-staking',
      },
      {
        dropLinkName: "Non working Pending Withdraw Request",
        dropLinkUrl: '/Pending-staking/nonworking_withdraw_request',
      },
      {
        dropLinkName: "Non working Rejected Withdraw Request",
        dropLinkUrl: '/Withdraw-Reject-staking',
      },
        // {
        //   dropLinkName: "Admin Pending Payouts",
        //   dropLinkUrl: '/adminpending',
        // },
      ],
    },
    // Mail
    {
      LinkIcon: <MailLock />,
      LinkName: "Mail",
      LinkUrl: '#',

      dropdown: [{
        dropLinkName: "Pending Ticket",
        dropLinkUrl: '/pending-tickets',
      },
      {
        dropLinkName: "Solved Ticket",
        dropLinkUrl: '/solved-ticket',
      },
      ],
    },
    // Deposit
    // {
    //   LinkIcon: <AddCard />,
    //   LinkName: "Deposit",
    //   LinkUrl: '#',

    //   dropdown: [
    //     {
    //       dropLinkName: "USDT Deposit History",
    //       dropLinkUrl: '/deposit_history',
    //     },
    //     {
    //     dropLinkName: "Fund Distribution",
    //     dropLinkUrl: '/SendWallet',
    //   },
    //   // {
    //   //   dropLinkName: "Send Credit Fund",
    //   //   dropLinkUrl: '/SendIncomeWallet',
    //   // },
    //   {
    //     dropLinkName: "Fund History",
    //     dropLinkUrl: '/fund_history',
    //   },
      
    //   ],
    // },

    // {
    //   LinkIcon: <AddCard />,
    //   LinkName: "Pin",
    //   LinkUrl: '#',

    //   dropdown: [{
    //     dropLinkName: "Pin Distribution",
    //     dropLinkUrl: '/SendPin',
    //   },
    //   // {
    //   //   dropLinkName: "Send Credit Fund",
    //   //   dropLinkUrl: '/SendIncomeWallet',
    //   // },
    //   {
    //     dropLinkName: "Pin History",
    //     dropLinkUrl: '/PinHistory',
    //   },
    //   ],
    // },

  ];


  return (
    <>


      <div className="header-section">


      </div>

      <div className="sidebar-sec">
        <div className="inner-sec">
          <div className="left">
            <div className="logo">
              <Link to="/dashboard">
                <img src={logo} alt="logo" />
                <span>{shortTitle}</span>
              </Link>
            </div>
            <div className="links">
              <Accordion defaultActiveKey={''}>

                {HeaderData.map((item: any, index: any) => (
                  <>
                    {item.dropdown == null &&
                      <Accordion.Item eventKey={'links' + index}>
                        <Link to={item.LinkUrl} className="accordion-button" onClick={() => {togglerBarRemove()}}> {item.LinkIcon} <span>{item.LinkName}</span> </Link>
                      </Accordion.Item>}

                    {item.dropdown && (

                      <Accordion.Item eventKey={'links' + index}>
                        <Accordion.Header>{item.LinkIcon} <span>{item.LinkName}</span></Accordion.Header>
                        <Accordion.Body>
                          {item.dropdown.map((subItem: any) => (
                            <Link key={subItem.dropLinkName} to={subItem.dropLinkUrl} onClick={() => {togglerBarRemove()}}> {subItem.dropLinkName} <KeyboardArrowRightTwoToneIcon /> </Link>
                          ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    )}
                  </>

                ))}

                <Accordion.Item eventKey={'linksd'}>
                  <button className="accordion-button main_button" onClick={Logout}> <LogoutRounded /> <span>Logout</span> </button>
                </Accordion.Item>
              </Accordion>
            </div>

          </div>
        </div>
      </div>
      <div className="___sidebarOverly" onClick={() => {togglerBarRemove()}}></div>




      <Modal
        open={RightSIdebar}
        onClose={() => {
          onCloseRightSIdebar();
          togglerBarRemove();
        }}
        classNames={{
          overlay: "P2P-PayOverlay",
          modal: "Header__sidebar__right",
          modalAnimationIn: "customEnterModalAnimation",
          modalAnimationOut: "customLeaveModalAnimation",
        }}
        animationDuration={800}
      >
        <div className="right_header-sidebar">
          <div className="">
            <h4>More services</h4>
          </div>
          <div className="inner_links">
            <Link className="Responsive_link" to="/dashboard" onClick={() => {onCloseRightSIdebar()}}>
              Dashboard
            </Link>

            <Accordion className="mob_accordian">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon />
                  Participants
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/user" onClick={() => {onCloseRightSIdebar()}}>
                      <li> All Participants </li>
                    </Link>
                    <Link to="/paidUsers " onClick={() => {onCloseRightSIdebar()}}>
                      <li> Active Participants</li>
                    </Link>
                    <Link to="/inactiveUsers" onClick={() => {onCloseRightSIdebar()}}>
                      <li>Inactive Participants</li>
                    </Link>

                    <Link to="/blockedUsers" onClick={() => {onCloseRightSIdebar()}}>
                      <li>Blocked Participants</li>
                    </Link>

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> User Popup Image
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/news " onClick={() => {onCloseRightSIdebar()}}>
                      <li>News</li>
                    </Link>
                    <Link to="/popup_upload" onClick={() => {onCloseRightSIdebar()}}>
                      <li>User Popup Banner</li>
                    </Link>
                    {/* <Link to="/roiList">
                      <li>ROI List</li>
                    </Link> */}

                    <Link to="/withdrawsetting" onClick={() => {onCloseRightSIdebar()}}>
                      <li>Withdraw Settings</li>
                    </Link>

                    {/* <Link to="/comissionpackage">
                      <li> Commission Package</li>
                    </Link> */}

                    {/* <Link to="/WithdrawSettings">
                      <li>Site Income Settings</li>
                    </Link> */}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Incomes
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    {/* <Link to="/trade_profit">
                      <li>Trade Profit</li>
                    </Link> */}


                    <Link to="/income/club_bonus" onClick={() => {onCloseRightSIdebar()}}>
                      <li> Club Bonus</li>
                    </Link>
                    <Link to="/income/level_bonus" onClick={() => {onCloseRightSIdebar()}}>
                      <li>Level Bonus</li>
                    </Link>




                    <Link to="/allincomes" onClick={() => {onCloseRightSIdebar()}}>
                      <li>All Incomes</li>
                    </Link>

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              {/* <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Trade Management
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/">
                      <li>Trade</li>
                    </Link>
                    <Link to="/ ">
                      <li>Trade History</li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item> */}

              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Withdraw
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/Withdraw" onClick={() => {onCloseRightSIdebar()}}>
                      <li>Withdraw Request</li>
                    </Link>
                    <Link to="/Approved" onClick={() => {onCloseRightSIdebar()}}>
                      <li>Approved Withdraw Request</li>
                    </Link>
                    <Link to="/Pending" onClick={() => {onCloseRightSIdebar()}}>
                      <li>Pending Withdraw Request</li>
                    </Link>
                    <Link to="/Rejected" onClick={() => {onCloseRightSIdebar()}}>
                      <li>Rejected Withdraw Request</li>
                    </Link>

                    {/* <Link to="/adminpending">
                      <li>Admin Pending Payouts</li>
                    </Link> */}

                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Mail
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/pending-tickets" onClick={() => {onCloseRightSIdebar()}}>
                      <li>Pending Ticket</li>
                    </Link>
                    <Link to="/solved-ticket" onClick={() => {onCloseRightSIdebar()}}>
                      <li>Solved Ticket </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <KeyboardArrowRightTwoToneIcon /> Deposit
                </Accordion.Header>
                <Accordion.Body>
                  <ul>
                    <Link to="/SendWallet" onClick={() => {onCloseRightSIdebar()}}>
                      <li> Fund Distribution </li>
                    </Link>

                    <Link to="/fund_history" onClick={() => {onCloseRightSIdebar()}}>
                      <li> Fund History  </li>
                    </Link>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Header;
