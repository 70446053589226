import React, { useEffect } from "react";
import "./Assets/css/style.scss";
import "./App.css";
import { BrowserRouter as Router, Routes, Route, Outlet, } from "react-router-dom";
import Index from "./Public/Index";
import Header from "./Public/Header"; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Login from "./Public/Login";
import Table from "./Public/Table";
import Form from "./Public/Form";
import Users from "./Public/Users";
import News from "./Public/News";
import WorkingPopup from "./Public/WorkingPopup";
import SiteIncome from "./Public/SiteIncome";
import WithdrawRequest from "./Public/WithdrawRequest";
import ApprovedWithdraw from "./Public/ApprovedWithdraw";
import PendingWithdraw from "./Public/PendingWithdraw";
import RejectedWithdraw from "./Public/RejectedWithdraw";
import AdminPendingwithdraw from "./Public/AdminPendingWithdraw";
import SolvedTickets from "./Public/SolvedTickets";
import SendDeposit from "./Public/SendDeposit";
import SendCredit from "./Public/SendCredit";
import FundHistory from "./Public/FundHistory";
import PrivateRoutes from "./config/PrivateRoutes";
import ActiveUsers from "./Public/ActiveUsers";
import InActiveUsers from "./Public/InActiveUsers";
import PendingTickets from "./Public/PendingTickets";
import ViewUser from "./Public/ViewUser";
import Income from "./Public/Income";
import IncomeByType from "./Public/IncomeByType";
import ROIHistory from "./Public/RoiHistory";
import BlockedUsers from "./Public/BlockedUsers";
import UserPopupImage from "./Public/UserPopupImage";
import WithdrawSettings from "./Public/WithdrawSettings";
import CommissionPackge from "./Public/CommissionPackage";
import PendingTRC20Withdraw from "./Public/PendingTRC20Withdraw";
import SendPin from "./Public/SendPin";
import PinHistory from "./Public/PinHistory";
import DepositHistory from "./Public/DepositHistory";
import EditUser from "./Public/EditUser";
import EditPassword from "./Public/EditPassword";
import PendingNonWithdraw from "./Public/PendingNonWithdraw";
import StakingWithdraw from "./Public/StakingWithdraw";
import StakingApprovedWithdraw from "./Public/StakingApprovedWithdraw";
import StakingRejectWithdraw from "./Public/StakingRejectWithdraw";
import RankUsers from "./Public/RankUsers";
import FundByType from "./Public/FundByType";
import LdpDetails from "./Public/LdpDetails";


function App() {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  });


  function Loginlayout() {
    return (
      <>
        <div className="">
          <Outlet />
        </div>
      </>
    );
  }
  function MainLayout() {
    return (
      <>
        <div className="">
          <Header />
          <Outlet />
        </div>
      </>
    );
  }
  

  return (
    <>
      <Router>
        <Routes>
          <Route element={<Loginlayout />}>
            <Route path="/*" element={<Login />} />
          </Route>
          <Route element={<PrivateRoutes />}>
            <Route element={<MainLayout />}>
              <Route path="/dashboard" element={<Index pageTitle={`Dashboard`} />} />
              <Route path="/table" element={<Table />} />
              <Route path="/form" element={<Form />} />
              <Route path="/user" element={<Users pageTitle={`Users`} />} />
              <Route path="/rank-user/:rank" element={<RankUsers pageTitle={`Royalty `} />} />

              <Route path="/edit-user/:user" element={<EditUser pageTitle={`Edit Users`} />} />
              <Route path="/edit-password/:user" element={<EditPassword pageTitle={`Edit User's Password`} />} />


              <Route path="/paidUsers" element={<ActiveUsers pageTitle={`Active Users`} />} />
              {/* <Route path="/inactiveUsers" element={<InActiveUsers pageTitle={`Inactive Users`} />} /> */}
              <Route path="/blockedUsers" element={<BlockedUsers pageTitle={`Block Users`}/>} />
              <Route path="/news" element={<News pageTitle={`News`} />} />
              <Route path="/popup_upload" element={<UserPopupImage pageTitle={`User Popup Banner`} />} />
              <Route path="/withdrawsetting" element={<WithdrawSettings pageTitle={`Withdraw Settings`} />} />
              <Route path="/comissionpackage" element={<CommissionPackge pageTitle={`Commission Package`} />} />
              <Route path="/CurrentUserPopup" element={<WorkingPopup pageTitle={`Working Popup`} />} />
              <Route path="/roiList" element={<ROIHistory pageTitle={`ROI Details`} />} />
              <Route path="/WithdrawSettings" element={<SiteIncome pageTitle={`Site Income`} />} />
              <Route path="/allincomes" element={<Income pageTitle={`All Incomes`} />} />
              <Route path="/income/:type" element={<IncomeByType pageTitle={`Incomes`} />} />
              <Route path="/fund/:type" element={<FundByType pageTitle={`Fund`} />} />

              <Route path="/Withdraw/:withdrawtype" element={<WithdrawRequest pageTitle={`Withdraw Request`} />} />
              <Route path="/Approved/:withdrawtype" element={<ApprovedWithdraw pageTitle={`Approved Withdraw`} />} />
              <Route path="/Pending/:withdrawtype" element={<PendingWithdraw pageTitle={`Pending Withdraw`} />} />
              <Route path="/Rejected/:withdrawtype" element={<RejectedWithdraw pageTitle={`Rejected Withdraw`} />} />

              <Route path="/Pending-staking/:withdrawtype" element={<PendingNonWithdraw pageTitle={`Pending Withdraw`} />} />
              
              <Route path="/Withdraw-staking" element={<StakingWithdraw pageTitle={`StakingWithdraw Request`} />} />
              <Route path="/Withdraw-Approved-staking" element={<StakingApprovedWithdraw pageTitle={`Approved Withdraw`} />} />
              <Route path="/Withdraw-Reject-staking" element={<StakingRejectWithdraw pageTitle={`Pending Withdraw`} />} />

              <Route path="/trc2-Pending" element={<PendingTRC20Withdraw pageTitle={`Pending TRC20 Withdraw`} />} />

              <Route path="/adminpending" element={<AdminPendingwithdraw pageTitle={`Admin Pending withdraw`} />} />
              <Route path="/pending-tickets" element={<PendingTickets pageTitle={`Ticket Details`} />} />
              <Route path="/solved-ticket" element={<SolvedTickets pageTitle={`Solved Tickets`} />} />
              <Route path="/SendWallet" element={<SendDeposit pageTitle={`Fund Distribution`} />} />
              <Route path="/SendPin" element={<SendPin pageTitle={`Pin Distribution`} />} />

              <Route path="/SendIncomeWallet" element={<SendCredit pageTitle={`Send Credit`} />} />
              <Route path="/fund_history" element={<FundHistory pageTitle={`Fund History`} />} />
              <Route path="/deposit_history" element={<DepositHistory pageTitle={`Deposit History`} />} />

              <Route path="/PinHistory" element={<PinHistory pageTitle={`Pin History`} />} />

              <Route path="/view-user/:username" element={<ViewUser pageTitle={`View User`} />} />
              <Route path="/ldp-details" element={<LdpDetails pageTitle={`LDP History`} />} />

            </Route>
          </Route>
        </Routes>
      </Router>
      <ToastContainer />
    </>
  );
}

export default App;
